<template>
  <ul class="list" v-if="list && list.length" v-loading="loading">
    <CardItem v-for="item in list" :key="item.id" :info="item" />
  </ul>
  <Empty v-else />
</template>

<script>
import { apiCourseBuyList } from '@/api/course'
import CardItem from '../components/cardItem'
import Empty from '@/components/Empty'

export default {
  components: {
    CardItem,
    Empty
  },
  data() {
    return {
      list: [],
      loading: false
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      this.loading = true
      const { data } = await apiCourseBuyList()
      this.list = data
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
</style>
