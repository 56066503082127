import { render, staticRenderFns } from "./cardItem.vue?vue&type=template&id=6dc7f250&scoped=true"
import script from "./cardItem.vue?vue&type=script&lang=js"
export * from "./cardItem.vue?vue&type=script&lang=js"
import style0 from "./cardItem.vue?vue&type=style&index=0&id=6dc7f250&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc7f250",
  null
  
)

export default component.exports